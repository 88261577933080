.mapboxgl-popup {
    max-width: 400px!important;
}
.d-flex {
    display: flex;
}
.flex-grow-1 {
    flex-grow: 1;
}
.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.my-0 {
    margin-bottom: 0;
    margin-top: 0;
}
.mt-0 {
    margin-top: 0;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.lh-1 {
    line-height: 1.2em;
}
.chiproot {
    color: rgba(0, 0, 0, 0.87);
    height: 32px;
    cursor: default;
    border: none;
    display: inline-flex;
    outline: none;
    padding: 0;
    font-size: 0.8125rem;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    align-items: center;
    white-space: nowrap;
    border-radius: 16px;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;
    margin: 4px;
}
.chiplabel {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-info {
    color: #fff;
    background-color: #22c3ed;
    border-color: #22c3ed;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.theme-dark .mapboxgl-popup-content {
    background-color: #424242;
}
.theme-dark .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: #424242;
}
.theme-dark .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #424242;
}
.theme-dark .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: #424242;
}
.theme-dark .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #424242;
}
.theme-dark .mapboxgl-popup-close-button {
    color: #fff;
}
.mapboxgl-popup-close-button {
    outline: none;
}
.mapboxgl-canvas {
    outline: none;
}

.mapboxgl-popup-anchor-right .actions {
    text-align: right;
}
.mapboxgl-popup-anchor-left .actions {
    text-align: left;
}